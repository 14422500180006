import { Environment, PerspectiveCamera, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import type * as THREE from "three";

export default function Scene({
	path,
}: {
	path: string;
}) {
	const { scene } = useGLTF(path);
	const ref = useRef<THREE.Group>(null);

	useFrame(() => {
		if (ref.current) {
			ref.current.rotation.x += 0.005;
			ref.current.rotation.z += 0.01;
		}
	});

	return (
		<>
			<PerspectiveCamera makeDefault position={[0, 0, 4]} />
			<ambientLight intensity={2} />
			<directionalLight position={[0.5, 0.5, 1]} intensity={3} castShadow />
			<Environment preset="city" />
			<mesh position={[0, 0, -1]} receiveShadow>
				<planeGeometry args={[100, 100]} />
				<meshPhongMaterial color={0x000066} />
			</mesh>

			<primitive
				ref={ref}
				object={scene}
				scale={1.5}
				castShadow
				receiveShadow
			/>
		</>
	);
}
