import { Loader, Preload, View } from "@react-three/drei"; // Removed Environment
import { Canvas } from "@react-three/fiber";
import Content from "./components/Content";
import Scene from "./components/Scene";
import "./App.css";
import { Suspense } from "react";

const MODEL_PATHS = [
  "/1.glb",
  "/2.glb",
  "/3.glb",
  "/4.glb",
  "/5.glb",
  "/6.glb",
];

export default function App() {
  return (
    <>
      <div id="canvas-container">
        {MODEL_PATHS.map((path, i) => (
          <View
            key={path}
            index={i}
            style={{ position: "relative", overflow: "hidden" }}
          >
            <Scene path={path} />
          </View>
        ))}

        <Canvas
          eventPrefix="client"
          className="canvas"
        >
          <Suspense fallback={null}>
            <View.Port />
            <Preload all />
          </Suspense>
        </Canvas>
        <Loader />
      </div>
      <Content />
    </>
  );
}