export default function Content() {
	return (
		<div id="content">
			<h1 className="title">$DICKBUTT</h1>

			<p className="description">
				DICKBUTT is a CC0 memecoin with one billion units. The NFT shows a
				Dickbutt sailing through the air. If you are one of the top holders,
				your $DICKBUTT shows a special edition Dickbutt riding a Cryptoad.
			</p>

			<img src="/composite.gif" alt="Composite" className="composite" />

			<p className="description">
				$DICKBUTT created an experimental NFT standard called ERC-718 (ERC-FLUX)
				where the appearance of the NFT changes based on the user's holdings.
			</p>

			<p className="description">
				The NFT was available for presale on the Ride platform:{" "}
				<a href="https://ride.wtf">https://ride.wtf</a>
			</p>

			<p className="description">CA:</p>

			<a href="https://ride.wtf" className="buy-button">
				buy on base
			</a>

			{[...Array(8)].map((_, i) => (
				<img
					key={i}
					src={`/100${i}.gif`}
					alt={`Dickbutt ${i + 1}`}
					className="floating-image"
					id={`img${i + 1}`}
				/>
			))}
		</div>
	);
}
